<template >
    <div class="row">
        <div class="col-md-12 text-end mb-2" v-if="rowdy.id">

        </div>
        <div class="col-md-12 mb-2">
            <div class="card">
                <div class="card-header">
                    <span class="h5">Basic Details</span>
                    <button type="button" class="btn btn-sm mb-2 btn-primary" style="float:right;" data-bs-toggle="modal"
                        data-bs-target="#gangsModal">
                        <i class="ri-add-line"></i> GANGS
                    </button>
                    <button type="button" class="btn btn-sm mb-2 btn-primary mx-2" style="float:right;"
                        data-bs-toggle="modal" data-bs-target="#casesModal">
                        <i class="ri-add-line"></i> CASES
                    </button>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-12">
                                    <label>Name</label>
                                    <input type="text" class="form-control" :class="{ 'is-invalid': errors.rowdies_name }"
                                        v-model="rowdies.name" />
                                    <span v-if="errors.rowdies_name"><small class="text-danger">{{ errors.rowdies_name[0]
                                    }}</small></span>
                                </div>
                                <div class="col-md-6">
                                    <label>Age</label>
                                    <input type="text" class="form-control" :class="{ 'is-invalid': errors.rowdies_age }"
                                        v-model="rowdies.age" />
                                    <span v-if="errors.rowdies_age"><small class="text-danger">{{ errors.rowdies_age[0]
                                    }}</small></span>
                                </div>
                                <div class="col-md-6">
                                    <label>Gender</label>
                                    <select class="form-control" :class="{ 'is-invalid': errors.rowdies_gender }"
                                        v-model="rowdies.gender">
                                        <option value="">Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    <span v-if="errors.rowdies_gender"><small class="text-danger">{{
                                        errors.rowdies_gender[0]
                                    }}</small></span>
                                </div>
                                <div class="col-md-12">
                                    <label>Family Details</label>
                                    <textarea class="form-control" :class="{ 'is-invalid': errors.rowdies_family_details }"
                                        v-model="rowdies.family_details"></textarea>
                                    <span v-if="errors.rowdies_family_details"><small class="text-danger">{{
                                        errors.rowdies_family_details[0] }}</small></span>
                                </div>
                                <div class="col-md-12">
                                    <label>Address</label>
                                    <textarea class="form-control" :class="{ 'is-invalid': errors.rowdies_address }"
                                        v-model="rowdies.address"></textarea>
                                    <span v-if="errors.rowdies_address"><small class="text-danger">{{
                                        errors.rowdies_address[0]
                                    }}</small></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 rowdy-photo">
                            <div class="media-profile mt-4">
                                <div class="media-img">
                                    <img :src="rowdies.photo? rowdies.photo : 'assets/images/default_user_image.jpg'" class="img-fluid ms-4" alt="user image">
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="file" class="form-control" id="customFile"
                                    :class="{ 'is-invalid': errors.avatar }" @change="onImageChange($event)"
                                    accept="image/*" ref="attachment"/>
                            </div>
                            <span v-if="errors.avatar" class="invalid-feedback">{{ errors.avatar[0]
                            }}</span>
                        </div>
                        <div class="col-md-12">
                            <label>Summary</label>
                            <textarea class="form-control" :class="{ 'is-invalid': errors.rowdies_summary }"
                                v-model="rowdies.summary"></textarea>
                            <span v-if="errors.rowdies_summary"><small class="text-danger">{{ errors.rowdies_summary[0]
                            }}</small></span>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">
                    <!-- <button class="btn btn-danger btn-sm me-2" @click="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button> -->
                    <button v-if="!update" class="btn btn-primary btn-sm" @click="AddRowdies()"><i
                            class="ri-save-line icon_hgt"></i> SUBMIT</button>
                    <button v-else class="btn btn-primary btn-sm " @click="UpdateRowdies()"><i
                            class="ri-save-line icon_hgt"></i> Update</button>

                </div>
            </div>
        </div>
        <div class="col-md-12 mb-2" v-if="rowdy.id">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-sm-4">
                            <span class="h5 pt-2">Gangs</span>
                        </div>
                        <div class="col-sm-6 offset-2">
                            <input type="text" v-model="search_gang" class="form-control form-control-sm"
                                placeholder="Search Gangs..." @keypress.enter="searchGangs()">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm table-bordered table-striped mb-3"
                            v-for="(gang_type, key) in search_gangs" :key="key" v-show="search_gangs[key].length">
                            <thead style="vertical-align: middle;">
                                <tr>
                                    <td colspan="4" style="background-color:  rgb(225, 237, 246);padding-top: 10px;">
                                        <h6>Gang Type: <span class="text-primary">{{ key }} </span></h6>
                                    </td>
                                </tr>
                                <tr class="text-center">
                                    <th>Sl.No</th>
                                    <th>Rowdy Name</th>
                                    <th>No. Of. Cases</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-center" v-for="gang, key in gang_type">
                                    <td>{{ key + 1 }}</td>
                                    <td style="text-align: left;">
                                        <a href="javascrit:void(0)" @click="getRowdy(gang.another_rowdy.rowdy_id)">
                                            {{ gang.another_rowdy?.name }}
                                        </a>
                                    </td>
                                    <td style="width: 10%;">{{ gang.another_rowdy?.no_of_cases }}</td>
                                    <td style="width: 5%;">
                                        <button type="button" class="btn btn-outline-danger btn-sm"
                                            @click="deleteGang(gang.rowdies_gang_id)">
                                            <i class="ri-delete-bin-line icon_hgt"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12" v-if="rowdy.id">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-sm-4">
                            <span class="h5 pt-2">Cases</span>
                        </div>
                        <div class="col-sm-6 offset-2">
                            <input type="text" class="form-control form-control-sm" v-model="search_case"
                                placeholder="Search Cases..." @keypress.enter="searchCases()">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive table-responsive-sm">
                        <table class="table table-sm table-bordered table-striped mb-0">
                            <thead>
                                <tr class="text-center">
                                    <th>Sl.No</th>
                                    <th>Division</th>
                                    <th>Police Station</th>
                                    <th>Present Status</th>
                                    <th>Crime Details</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-center" v-for="(rowdy_case, key) in search_cases"
                                    style="vertical-align: middle">
                                    <td>{{ key + 1 }}</td>
                                    <td>{{ rowdy_case.division?.division }}</td>
                                    <td>{{ rowdy_case.police_station?.police_station }}</td>
                                    <td style="text-align:left;">{{ rowdy_case.present_status }}</td>
                                    <td style="text-align:left;">{{ rowdy_case.crime_details }}</td>
                                    <td class="d-flex justify-content-center gap-1">
                                        <button type="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal"
                                            data-bs-target="#editCase" @click="editCase(rowdy_case)">
                                            <i class="ri-edit-line icon_hgt"></i>
                                        </button>
                                        <button type="button" class="btn btn-outline-danger btn-sm ms-2"
                                            @click="deleteCase(rowdy_case.rowdies_case_id)">
                                            <i class="ri-delete-bin-line icon_hgt"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Gangs Modal -->
        <div class="modal fade" id="gangsModal" ref="gangsModal" tabindex="-1" aria-labelledby="gangsModalLabel"
            aria-hidden="true">
            <div class="modal-dialog  modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="gangsModalLabel">{{ rowdy.name }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <!-- <div class="col-md-4">
                            <label>Rowdy Name</label>
                            <input type="text" class="form-control" :class="{ 'is-invalid': errors.rowdy_name }" :value="rowdy.name" readonly/>
                            <span v-if="errors.rowdy_name"><small class="text-danger">{{ errors.rowdy_name[0] }}</small></span>
                        </div> -->
                            <div class="col-md-12">
                                <label>Gang Type</label>
                                <select class="form-control" :class="{ 'is-invalid': errors.gang_type }"
                                    v-model="gang.gang_type">
                                    <option value="">-- Select Gang --</option>
                                    <option value="Main">Main</option>
                                    <option value="Opposit">Opposit</option>
                                    <option value="Same">Same</option>
                                </select>
                                <span v-if="errors.gang_type"><small class="text-danger">{{ errors.gang_type[0]
                                }}</small></span>
                            </div>
                            <div class="col-md-12">
                                <label>Another Rowdy</label>
                                <select class="form-control" :class="{ 'is-invalid': errors.rowdy_id }"
                                    v-model="gang.another_rowdy_id">
                                    <option value="">-- Select Rowdy --</option>
                                    <option v-for="(rowdy, key) in all_rowdies" :key="key" :value="rowdy.rowdy_id">
                                        {{ rowdy.name }}</option>
                                </select>
                                <span v-if="errors.rowdy_id"><small class="text-danger">{{ errors.rowdy_id[0]
                                }}</small></span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" id="gangClose"
                            data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="addGangs()">Save changes</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Cases Modal -->
        <div class="modal fade" id="casesModal" tabindex="-1" aria-labelledby="editCaseLabel" aria-hidden="true">
            <div class="modal-dialog  modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="casesModalLabel">{{ rowdy.name }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <!-- <div class="col-md-4">
                            <label>Rowdy Name</label>
                            <input type="text" class="form-control" :class="{ 'is-invalid': errors.rowdy_name }" :value="rowdy.name" readonly/>
                            <span v-if="errors.rowdy_name"><small class="text-danger">{{ errors.rowdy_name[0] }}</small></span>
                        </div> -->
                            <div class="col-md-6">
                                <label>Division</label>
                                <select class="form-control" :class="{ 'is-invalid': errors.division_id }"
                                    v-model="rowdy_case.division_id" @change="getPoliceStation()">
                                    <option value="">-- Select Division --</option>
                                    <option v-for="(division, key) in divisions" :key="key" :value="division.division_id">
                                        {{ division.division }}</option>
                                </select>
                                <span v-if="errors.division_id"><small class="text-danger">{{ errors.division_id[0]
                                }}</small></span>
                            </div>
                            <div class="col-md-6">
                                <label>Police Station</label>
                                <select class="form-control" :class="{ 'is-invalid': errors.police_station_id }"
                                    v-model="rowdy_case.police_station_id">
                                    <option value="">-- Select Police Station --</option>
                                    <option v-for="(police_station, key) in police_stations" :key="key"
                                        :value="police_station.police_station_id">{{ police_station.police_station }}
                                    </option>
                                </select>
                                <span v-if="errors.police_station_id"><small class="text-danger">{{
                                    errors.police_station_id[0] }}</small></span>
                            </div>
                            <div class="col-md-12">
                                <label>Present Status</label>
                                <textarea class="form-control" :class="{ 'is-invalid': errors.present_status }"
                                    v-model="rowdy_case.present_status"></textarea>
                                <span v-if="errors.present_status"><small class="text-danger">{{ errors.present_status[0]
                                }}</small></span>
                            </div>
                            <div class="col-md-12">
                                <label>Crime Details</label>
                                <textarea class="form-control" :class="{ 'is-invalid': errors.crime_details }"
                                    v-model="rowdy_case.crime_details"></textarea>
                                <span v-if="errors.crime_details"><small class="text-danger">{{ errors.crime_details[0]
                                }}</small></span>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" id="caseClose"
                            data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="addCases()">Save</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Update Case Modal -->
        <div class="modal fade" id="editCase" ref="editCaseModal" tabindex="-1" aria-labelledby="editCaseLabel"
            aria-hidden="true" v-show="edit_modal_show">
            <div class="modal-dialog  modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="casesModalLabel">{{ rowdy.name }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <!-- <div class="col-md-4">
                            <label>Rowdy Name</label>
                            <input type="text" class="form-control" :class="{ 'is-invalid': errors.rowdy_name }" :value="rowdy.name" readonly/>
                            <span v-if="errors.rowdy_name"><small class="text-danger">{{ errors.rowdy_name[0] }}</small></span>
                        </div> -->
                            <div class="col-md-6">
                                <label>Division</label>
                                <select class="form-control" :class="{ 'is-invalid': errors.division_id }"
                                    v-model="edit_rowdy_case.division_id" @change="getPoliceStation()">
                                    <option value="">-- Select Division --</option>
                                    <option v-for="(division, key) in divisions" :key="key" :value="division.division_id">
                                        {{ division.division }}</option>
                                </select>
                                <span v-if="errors.division_id"><small class="text-danger">{{ errors.division_id[0]
                                }}</small></span>
                            </div>
                            <div class="col-md-6">
                                <label>Police Station</label>
                                <select class="form-control" :class="{ 'is-invalid': errors.police_station_id }"
                                    v-model="edit_rowdy_case.police_station_id">
                                    <option value="">-- Select Police Station --</option>
                                    <option v-for="(police_station, key) in police_stations" :key="key"
                                        :value="police_station.police_station_id">{{ police_station.police_station }}
                                    </option>
                                </select>
                                <span v-if="errors.police_station_id"><small class="text-danger">{{
                                    errors.police_station_id[0] }}</small></span>
                            </div>
                            <div class="col-md-12">
                                <label>Present Status</label>
                                <textarea class="form-control" :class="{ 'is-invalid': errors.present_status }"
                                    v-model="edit_rowdy_case.present_status"></textarea>
                                <span v-if="errors.present_status"><small class="text-danger">{{ errors.present_status[0]
                                }}</small></span>
                            </div>
                            <div class="col-md-12">
                                <label>Crime Details</label>
                                <textarea class="form-control" :class="{ 'is-invalid': errors.crime_details }"
                                    v-model="edit_rowdy_case.crime_details"></textarea>
                                <span v-if="errors.crime_details"><small class="text-danger">{{ errors.crime_details[0]
                                }}</small></span>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" id="caseClose" ref="caseUpdate"
                            data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="updateCase()">Save changes</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "RowdiesCreate",
    data() {
        return {
            rowdies: {
                name: '',
                age: '',
                gender: '',
                address: '',
                family_details: '',
                summary: '',
                photo: null
            },
            errors: [],
            all_rowdies: [],
            gangs: [],
            search_gangs: [],
            search_gang: '',
            search_cases: [],
            search_case: '',
            cases: [],
            rowdy: {
                id: '',
                name: '',
            },
            gang: {
                rowdy_id: '',
                gang_type: '',
                another_rowdy_id: '',
            },
            rowdy_case: {
                rowdy_id: '',
                division_id: '',
                police_station_id: '',
                crime_details: '',
                present_status: '',
            },

            edit_rowdy_case: {
                rowdy_id: '',
                division_id: '',
                police_station_id: '',
                crime_details: '',
                present_status: '',
            },
            divisions: [],
            police_stations: [],
            update: false,
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (to.name == "rowdiesEdit") {
                vm.getRowdy(to.params.rowdy_id)
                vm.update = true;
                // vm.$refs.project_type_id.focus();                    
            }
        });
    },
    methods: {
        AddRowdies() {
            let vm = this;
            let loader = vm.$loading.show();
            const formData = new FormData()
            formData.append('name', vm.rowdies.name)
            formData.append('age', vm.rowdies.age)
            formData.append('gender', vm.rowdies.gender)
            formData.append('address', vm.rowdies.address)
            formData.append('family_details', vm.rowdies.family_details)
            formData.append('summary', vm.rowdies.summary)
            if (vm.$refs.attachment.files[0]) {
                formData.append('photo', vm.$refs.attachment.files[0])
            }

            let uri = { uri: "addRowdy", data: formData };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", "Rowdies record is added successfully");
                    // console.log('AddRowdy:---',response.data)
                    // vm.rowdy.id = response.data.data.rowdy_id
                    // vm.rowdy.name = response.data.data.name
                    // vm.getRowdies();
                    vm.$router.push("/rowdies/edit/" + response.data.data.rowdy_id);
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        UpdateRowdies() {
            let vm = this;
            let loader = vm.$loading.show();
            const formData = new FormData()
            formData.append('rowdy_id', vm.rowdy.id)
            formData.append('name', vm.rowdies.name)
            formData.append('age', vm.rowdies.age)
            formData.append('gender', vm.rowdies.gender)
            formData.append('address', vm.rowdies.address)
            formData.append('family_details', vm.rowdies.family_details)
            formData.append('summary', vm.rowdies.summary)
            if (vm.$refs.attachment.files[0]) {
                formData.append('photo', vm.$refs.attachment.files[0])
            }

            let uri = { uri: "updateRowdy", data: formData };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", "Rowdies record is updated successfully");
                    // vm.$router.push("/rowdies/edit/" + response.data.data.rowdy_id);
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        addGangs() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.gang.rowdy_id = vm.rowdy.id;
            let uri = { uri: "addRowdyGang", data: vm.gang };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    // vm.gangs = response.data.data;
                    vm.getRowdy(vm.rowdy.id);
                    vm.gang.rowdy_id = '';
                    vm.gang.gang_type = '';
                    vm.gang.another_rowdy_id = '';
                    document.getElementById('gangClose').click();
                    vm.$store.dispatch("success", "Gang record is added successfully");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        addCases() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.rowdy_case.rowdy_id = vm.rowdy.id;
            let uri = { uri: "addRowdiesCase", data: vm.rowdy_case };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    // vm.rowdies = response.data.data;
                    vm.getRowdy(vm.rowdy.id);
                    vm.rowdy_case.rowdy_id = '';
                    vm.rowdy_case.division_id = '';
                    vm.rowdy_case.police_station_id = '';
                    vm.rowdy_case.crime_details = '';
                    vm.rowdy_case.present_status = '';
                    document.getElementById('caseClose').click();
                    vm.$store.dispatch("success", "Case record is added successfully");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getRowdy(rowdy_id) {
            let vm = this;
            let uri = { uri: "getRowdy", data: { rowdy_id: rowdy_id } };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.rowdies = response.data.data
                    vm.gangs = response.data.data.gangs
                    vm.cases = response.data.data.cases
                    vm.rowdy.id = response.data.data.rowdy_id
                    vm.rowdy.name = response.data.data.name
                    vm.search_gangs = Object.assign({}, vm.gangs);
                    vm.search_cases = vm.cases;
                    vm.getRowdies();

                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getRowdies() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getAllRowdies", };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    // console.log('getAllRowdies:----',response.data.data)
                    vm.all_rowdies = response.data.data;
                    vm.getDivision();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        // getCases(){
        //     let vm = this;
        //     let loader = vm.$loading.show();
        //     let uri = { uri: "",};
        //     vm.$store.dispatch("post", uri)
        //     .then(function (response) {
        //         loader.hide();
        //         vm.cases = response.data.data;
        //     })
        //     .catch(function (error) {
        //         loader.hide();
        //         vm.errors = error.response.data.errors;
        //         vm.$store.dispatch("error", error.response.data.message);
        //     });
        // },

        getDivision() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getAllDivisions", };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    // console.log('getdivisions:----',response.data.data)
                    vm.divisions = response.data.data;
                    // vm.getPoliceStation();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getPoliceStation() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getAllPoliceStations", data: { 'division_id': vm.rowdy_case.division_id } };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    // console.log('PoliceStations:----',response.data.data)
                    vm.police_stations = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        deleteGang(key) {
            if (confirm("Are you sure you want to delete")) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: 'deleteRowdiesGang', data: { 'rowdies_gang_id': key } };
                // }
                // else{
                //     let uri = { uri:'deleteRowdyCase', data:{'rowdies_gang_id': key}};
                // }
                vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        // console.log('response:delete:---',response);
                        vm.$store.dispatch("success", response.data.message);
                        vm.getRowdy(vm.rowdy.id);
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },

        deleteCase(key) {
            if (confirm("Are you sure you want to delete")) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: 'deleteRowdyCase', data: { 'rowdies_case_id': key } };
                vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();

                        vm.$store.dispatch("success", response.data.message);
                        vm.getRowdy(vm.rowdy.id);
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },

        editCase(rowdy_case) {
            this.edit_rowdy_case = rowdy_case;
            this.getPoliceStation();
        },

        updateCase() {
            this.$store.dispatch('post', { uri: 'updateRowdiesCase', data: this.edit_rowdy_case })
                .then(response => {
                    this.$refs.caseUpdate.click();
                    this.$store.dispatch("success", response.data.message);
                    this.getRowdy(this.rowdy.id);
                })
        },

        discard() {
            let vm = this;
            vm.rowdies.name = ''
            vm.rowdies.age = ''
            vm.rowdies.gender = ''
            vm.rowdies.address = ''
            vm.rowdies.family_details = ''
            vm.rowdies.summary = ''
            vm.rowdy.id = ''
            vm.rowdy.name = ''
            vm.$router.push("/rowdies/create/");
        },

        searchGangs() {
            let search_text = this.search_gang.toLowerCase();
            let gangs = Object.assign({}, this.search_gangs);
            for (var key in this.gangs) {
                gangs[key] = this.gangs[key].filter(ele => {
                    return ele.another_rowdy.name.toLowerCase().search(search_text) != -1;
                })
            }

            this.search_gangs = Object.assign({}, gangs);

            if (this.search_gang == '') {
                this.search_gangs = Object.assign({}, this.gangs);
            }
        },

        searchCases() {
            let search_text = this.search_case.toLowerCase();
            let cases = this.cases.filter(ele => {
                return ele.division.division.toLowerCase().search(search_text) != -1
                    || ele.police_station.police_station.toLowerCase().search(search_text) != -1
                    || ele.present_status.toLowerCase().search(search_text) != -1
                    || ele.crime_details.toLowerCase().search(search_text) != -1;
            });

            this.search_cases = cases;

            if (this.search_case == '') {
                this.search_cases = this.cases;
            }
        },

        onImageChange(e) {
            // e.preventDefault();
            let vm = this;
            vm.image = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(vm.image);
            reader.onload = (e) => {
                vm.rowdies.photo = e.target.result;
            };
        },
    }
}
</script>
<style scoped>
.rowdy-photo {
    display: flex;
    background-color: rgb(255, 243, 221);
    border-radius: 5%;
    flex-direction: column;
    justify-content:space-between;
    margin-left: 5%;
}
</style>