<template>
    <div class="row">
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">
                    Upload Rowdy Sheet
                </div>
                <div class="card-body">
                    <input type="file" class="form-control" ref="attachment" @change="removeError()"/>
                    <span v-if="errors.upload_file"><small class="text-danger">{{ errors.upload_file[0] }}</small></span>
                </div>
                <div class="card-footer text-end">
                    <button type="button" class="btn btn-sm btn-primary"  
                        @click="uploadRowdy()">
                        Upload
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">
                    Instruction
                </div>
                <div class="card-body">
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name:'RowdiesFileUpload',
    data(){
        return{
            errors:[],
        }
    },
    methods:{
        removeError(){
            delete this.errors.upload_file
        },
        uploadRowdy(){
            let vm = this;
            const formData = new FormData()
            if(vm.$refs.attachment.files[0]){
                let loader = vm.$loading.show();
                formData.append('attachment', vm.$refs.attachment.files[0])
                let uri = { uri: "uploadRowdySheeter", data: formData };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", "File uploaded successfully");
                    // console.log('AddRowdy:---',response.data)
                    // vm.rowdy.id = response.data.data.rowdy_id
                    // vm.rowdy.name = response.data.data.name
                    // vm.getRowdies();
                    // vm.$router.push("/rowdies/edit/" + response.data.data.rowdy_id);
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
            else{
                vm.errors.upload_file=['Please select a file to upload']
            }
        },
    }
}
</script>