<template>
    <div class="card">
        <div class="card-header">
            User List
        </div>
        <div class="card-body">
            <div class="table-responsive table-responsive-sm">
                <table class="table table-sm table-bordered table-striped mb-0">
                    <thead>
                        <tr class="text-center">
                            <th>Sl.No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Mobile Number</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center" v-for="(user, key) in UserList">
                            <td>{{ key+1 }}</td>
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.role }}</td>
                            <td>{{ user.mobile_no }}</td>
                            <td>
                                <button type="button" class="btn btn-sm btn-outline-success me-2"  
                                    @click="editUser(user.user_id)">
                                    <i class="ri-pencil-line icon_hgt"></i>
                                </button>
                                <button type="button" class="btn btn-outline-danger btn-sm" 
                                    @click="deleteUser(user.user_id)">
                                    <i class="ri-delete-bin-line icon_hgt"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
export default{
    name:"UserList",
    data() {
        return{
            UserList:[],            
            meta: {
                search: '',
                order_by: "asc",
                keyword: "user_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
            },
            status: ''
        }
    },
    mounted(){
        this.getUserList(); 
    },
    
    components:{
        Pagination,
    },
    methods:{     
        getUserList(){
            let vm = this;
            let uri = { uri: "paginateUsers", data:vm.meta};
            vm.$store.dispatch("post", uri)
            .then(function (response) {
                vm.UserList = response.data.data
                vm.meta.totalRows = response.data.meta.total;
                vm.meta.lastPage = response.data.meta.last_page;
                vm.meta.from = response.data.meta.from;
                vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
            })
            .catch(function (error) {
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            });
        },
        editUser(user_id){
            this.$router.push("/user/edit/" + user_id);
        },

        deleteUser(user_id){
            if (confirm("Are you sure you want to delete")) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri:'deleteUser', data:{'user_id': user_id}};
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", response.data.message);
                    vm.getUserList();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        },
        onPageChange(page) {
            this.meta.page = page;
            this.getUserList();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },
    }

}
</script>