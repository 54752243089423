<template>
    <div class="page-sign d-block py-0">
        <div class="row g-0">
            <div class="col-md-7 col-lg-5 col-xl-4 col-wrapper">
                <div class="card card-sign">
                    <div class="card-header">
                        <div class="text-center">
                            <img src="assets/images/logo.png" class="img" width="100" />
                        </div>
                        <div class="text-center">
                            <a href="#" class="text-center header-logo mb-3 mt-2">Rowdy Sheeter</a>
                        </div>
                        <h3 class="card-title">Sign In</h3>
                        <p class="card-text">Welcome back! Please signin to continue.</p>
                    </div>
                    <form class="card-body" @submit.prevent="login">
                        <div class="mb-4">
                            <label class="form-label">Email address</label>
                            <input type="text" class="form-control" placeholder="Enter your email address" :class="{ 'is-invalid': errors.email }" v-model="user.email" ref="email" tabindex="1" />
                            <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                        </div>
                        <div class="mb-4">
                            <label class="form-label d-flex justify-content-between">
                                Password 
                                <router-link to="/forgot_password">Forgot password?</router-link>
                            </label>
                            <input type="password" class="form-control" placeholder="Enter your password" :class="{ 'is-invalid': errors.password }" v-model="user.password" tabindex="2" />
                            <span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
                        </div>
                        <button method="submit" class="btn btn-primary btn-sign" tabindex="3">
                            Sign In
                        </button>
                    </form>
                </div>
            </div>
            <div class="col d-none d-lg-block">
                <img src="assets/img/bg1.jpg" class="auth-img" alt="" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Login",
    data() {
        return {
            user: {
                email: "bharatesh@patterneffectslabs.com",
                password: "1qaz2wsx"
            },
            errors: [],
        };
    },
    mounted() {
        this.$refs.email.focus();
    },
    methods: {
        login() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store.dispatch("auth", { uri: "login", data: vm.user })
            .then(function (response) {
                loader.hide();
                vm.$store.dispatch("setUser", response.data.user);
                vm.$store.dispatch("setToken", response.data.token);
                vm.$router.push("/");
            })
            .catch(function (error) {
                loader.hide();
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            });
        },
    },
};
</script>
