<template>
    <div class="card">
        <div class="card-header">
            <div class="container">
                <div class="row">
                    <div class="col-sm-3 pt-2">
                        <span class="h5">Rowdies List</span>
                    </div>
                    <div class="col-sm-9">
                        <input type="text" class="form-control form-control-sm" v-model="meta.search" placeholder="Search Rowdies..." @keypress.enter="getRowdyList()">
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive table-responsive-sm">
                <table class="table table-sm table-bordered table-striped mb-0">
                    <thead>
                        <tr class="text-center">
                            <th>Sl.No</th>
                            <th @click="sort('name')">
                                Name
                                <span>
                                    <i v-if="meta.keyword == 'name' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                    <i v-else-if="meta.keyword == 'name' && meta.order_by == 'desc'"
                                        class="ri-arrow-down-line"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </span>
                            </th>
                            <th>
                                No. of. Cases
                            </th>
                            <th @click="sort('age')">
                                Age
                                <span>
                                    <i v-if="meta.keyword == 'age' && meta.order_by == 'asc'" class="ri-arrow-up-line"></i>
                                    <i v-else-if="meta.keyword == 'age' && meta.order_by == 'desc'"
                                        class="ri-arrow-down-line"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </span>
                            </th>
                            <th @click="sort('gender')">
                                Gender
                                <span>
                                    <i v-if="meta.keyword == 'gender' && meta.order_by == 'asc'"
                                        class="ri-arrow-up-line"></i>
                                    <i v-else-if="meta.keyword == 'gender' && meta.order_by == 'desc'"
                                        class="ri-arrow-down-line"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </span>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center" v-for="(rowdylist, key) in rowdyList">
                            <td>{{ meta.from + key }}</td>
                            <td style="text-align:left">{{ rowdylist.name }}</td>
                            <td style="text-align: center;">{{ rowdylist.no_of_cases }}</td>
                            <td>{{ rowdylist.age }}</td>
                            <td>{{ rowdylist.gender }}</td>
                            <td>
                                <button type="button" class="btn btn-sm btn-outline-success me-2"
                                    @click="editRowdy(rowdylist.rowdy_id)">
                                    <i class="ri-pencil-line icon_hgt"></i>
                                </button>
                                <button type="button" class="btn btn-outline-danger btn-sm"
                                    @click="deleteRowdy(rowdylist.rowdy_id)">
                                    <i class="ri-delete-bin-line icon_hgt"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                @pagechanged="onPageChange" />
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
export default {
    name: "RowdiesCreate",
    data() {
        return {
            rowdyList: [],
            meta: {
                search: '',
                order_by: "asc",
                keyword: "rowdy_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
            },
        }
    },
    mounted() {
        this.getRowdyList();
    },
    components: {
        Pagination,
    },
    methods: {
        getRowdyList() {
            let vm = this;
            let uri = { uri: "paginateRowdies", data: vm.meta };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    console.log('rowdiesbefor:---', response.data.data)
                    vm.rowdyList = response.data.data
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    console.log('error:---', error)
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        editRowdy(rowdy_id) {
            this.$router.push("/rowdies/edit/" + rowdy_id);
        },
        deleteRowdy(rowdy_id) {
            if (confirm("Are you sure you want to delete")) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: 'deleteRowdy', data: { 'rowdy_id': rowdy_id } };
                vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", response.data.message);
                        vm.getRowdyList();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        },
        onPageChange(page) {
            this.meta.page = page;
            this.getRowdyList();
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.getRowdyList();
        },
    }

}
</script>