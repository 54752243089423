<template>
	<div class="d-sm-flex align-items-center justify-content-between">
		<div>
			<ol class="breadcrumb fs-sm mb-1">
				<!-- <li class="breadcrumb-item"><a href="#">Dashboard</a></li> -->
				<!-- <li class="breadcrumb-item active" aria-current="page">Product Management</li> -->
			</ol>
			<h4 class="main-title mb-0">Welcome to Dashboard</h4>
		</div>
	</div>
	<hr>
	<div class="row g-2 mt-2">
		<div class="col-sm-4">
			<router-link to="/users">
				<div class="task-category p-3 bg-info">
					<h2 class="category-percent mb-2 text-center">{{ dashboard.user_counts }}</h2>
					<div class="divider px-3"><span><h5 class="text-white">USERS</h5></span></div>					
				</div>
			</router-link>
		</div>
		<div class="col-sm-4">
			<router-link to="/rowdies">
				<div class="task-category p-3 bg-warning">
					<h2 class="category-percent mb-2 text-center">{{ dashboard.rowdies_counts }}</h2>
					<div class="divider px-3"><span><h5 class="text-white">ROWDIES</h5></span></div>
				</div>
			</router-link>
		</div>
	</div>
</template>
<script>
export default {
	name: 'UserDashboard',

	data() {
		return {
			dashboard: {
				user_counts: 0,
				rowdies_counts: 0
			}
		}
	},

	mounted() {
		this.getDashboardCounts();
	},

	methods: {
		getDashboardCounts() {
			this.$store.dispatch('post', { uri: 'getDashboardCounts' })
				.then(response => {
					this.dashboard = response.data;
				})
		}
	}
}
</script>