import { createRouter, createWebHashHistory } from "vue-router";
import store from '@/store/index.js';

import HomeView from "../views/HomeView.vue";
import Login from '@/views/auth/Login.vue'
import UserList from '@/views/user/UserList.vue'
import UserCreateView from '@/views/user/UserCreateView.vue'
import RowdiesList from '@/views/rowdy/RowdiesList.vue'
import RowdiesCreateView from '@/views/rowdy/RowdiesCreateView.vue'
import RowdiesFileUpload from '@/views/rowdy/RowdyFileUpload.vue'
import Profile from "@/views/auth/Profile.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";

const routes = [
	{
		path: "/login",
		name: "login",
		component: Login,
		meta: { unAuth: true, permission:'All' }
	},
	{
        path: "/forgot_password",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
	{
        path: "/profile",
        name: "profile",
        component: Profile,
    },
	{
		path: "/",
		name: "home",
		component: HomeView,
		meta: { auth: true, permission:'All' }  
	},
	{
		path: "/users/",
		name: "users",
		component: UserList,
		meta: { auth: true, permission:'All' }  
	},
	{
		path: "/user/create",
		name: "userCreate",
		component: UserCreateView,
		meta: { auth: true, permission:'All' }  
	},
	{
		path: "/user/edit/:user_id",
		name: "userEdit",
		component: UserCreateView,
		meta: { auth: true, permission:'All' }  
	},
	{
		path: "/rowdies/",
		name: "rowdies",
		component: RowdiesList,
		meta: { auth: true, permission:'All' }  
	},
	{
		path: "/rowdies/create",
		name: "rowdiesCreate",
		component: RowdiesCreateView,
		meta: { auth: true, permission:'All' }  
	},	
	{
		path: "/rowdies/edit/:rowdy_id",
		name: "rowdiesEdit",
		component: RowdiesCreateView,
		meta: { auth: true, permission:'All' }  
	},
	{
		path: "/rowdies/fileupload/",
		name: "rowdiesFileUpload",
		component: RowdiesFileUpload,
		meta: { auth: true, permission:'All' }  
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

router.beforeEach(function(to, _, next) {
	if (to.meta.auth && !store.getters.user) {
		next('/login');
	}
	else if(to.meta.unAuth && store.getters.user) {
		next('/');
	}
	else {
		next();
	}
});

export default router;
