<template>
	<div v-if="$store.getters.user">
		<Menu></Menu>
		<Header></Header>
		<div class="main main-app p-3 p-lg-4">
			<router-view></router-view>
			<!-- <Footer></Footer> -->
		</div>
	</div>
	<div v-else>
		<router-view></router-view>
	</div>
</template>
<script>
	import Menu from "./components/Menu.vue";
	import Header from "./components/Header.vue";
	import Footer from "./components/Footer.vue";
	
	export default {
		name: 'App',
		components: { Menu, Header, Footer },

		created() {
			if (sessionStorage.getItem("user")) {
				this.$store.dispatch('setUser', JSON.parse(sessionStorage.getItem("user")))
				sessionStorage.removeItem('user')
			}
			if (sessionStorage.getItem("token")) {
				this.$store.dispatch('setToken', sessionStorage.getItem("token"))
				sessionStorage.removeItem('token')
			}
			
			window.addEventListener("beforeunload", () => {
				sessionStorage.setItem("user", JSON.stringify(this.$store?.getters?.user))
				sessionStorage.setItem("token", this.$store?.getters?.token)
			})
		}
	};
</script>
<style>
	input, select, textarea{
		margin-bottom: 10px;
	}
</style>