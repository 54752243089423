<template >
    <div class="row">        
        <div class="col-md-12 mb-2">
            <div class="card">
                <div class="card-header"> {{ title }} </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Name</label>
                            <input type="text" placeholder="Enter Name" class="form-control" :class="{ 'is-invalid': errors.name }" v-model="user.name"/>
                            <span v-if="errors.name"><small class="text-danger">{{ errors.name[0] }}</small></span>
                        </div>
                        <div class="col-md-4">
                            <label>Email</label>
                            <input type="text" placeholder="Enter Email" class="form-control" :class="{ 'is-invalid': errors.email }" v-model="user.email"/>
                            <span v-if="errors.email"><small class="text-danger">{{ errors.email[0] }}</small></span>
                        </div>
                        <div class="col-md-4">
                            <label>Role</label>
                            <select class="form-control" :class="{ 'is-invalid': errors.role }" v-model="user.role">
                                <option value="">-- Select Role --</option>
                                <option value="Admin">Admin</option>
                                <option value="User">User</option>
                            </select>
                            <span v-if="errors.role"><small class="text-danger">{{ errors.role[0] }}</small></span>
                        </div>
                        <div class="col-md-4">
                            <label>Mobile Number</label>
                            <input type="text" placeholder="Enter Mobile Number" class="form-control" :class="{ 'is-invalid': errors.mobile_no }" v-model="user.mobile_no"/>
                            <span v-if="errors.mobile_no"><small class="text-danger">{{ errors.mobile_no[0] }}</small></span>
                        </div>
                        <div class="col-md-4" v-if="!update">
                            <label>Password</label>
                            <input type="password" placeholder="Enter Password" class="form-control" :class="{ 'is-invalid': errors.password }" v-model="user.password"/>
                            <span v-if="errors.password"><small class="text-danger">{{ errors.password[0] }}</small></span>
                        </div>                        
                        <div class="col-md-4">
                            <label></label>
                            <input type="file" class="form-control" ref="attachment" />
                        </div>
                        <div class="col-md-12">
                            <label>Address</label>
                            <textarea class="form-control" placeholder="Enter Address" :class="{ 'is-invalid': errors.update }" v-model="user.address"></textarea>
                            <span v-if="errors.update"><small class="text-danger">{{ errors.update[0] }}</small></span>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-end">                    
                    <button class="btn btn-danger btn-sm me-2" @click="discard()"><i class="ri-close-line icon_hgt"></i> DISCARD</button>
                    <button v-if="!update" class="btn btn-primary btn-sm" @click="AddUser()"><i class="ri-save-line icon_hgt"></i> SUBMIT</button>
                    <button v-else class="btn btn-primary btn-sm " @click="updateUser()"><i class="ri-save-line icon_hgt"></i> Update</button>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"RowdiesCreate",
        data() {
            return{
                title:'Create User',
                user:{
                    user_id:'',
                    name:'',
                    email:'',
                    mobile_no:'',
                    role:'',
                    address:'',
                    password:'',
                },
                errors:[],               
                update:false,

            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (to.name == "userEdit") { 
                    vm.title = 'Update User'                   
                    vm.getUser(to.params.user_id)
                    vm.update=true;                
                } 
            });
        },
        methods:{
           
            // addCases(){
            //     this.$router.push("/project_update/" + project.project_id);
            // },
            AddUser(){
                let vm = this;
                let loader = vm.$loading.show();
                const formData = new FormData()
				formData.append('name', vm.user.name)
				formData.append('email', vm.user.email)
				formData.append('mobile_no', vm.user.mobile_no)
                formData.append('role', vm.user.role)
                formData.append('address', vm.user.address)
                formData.append('password', vm.user.password)
                if(vm.$refs.attachment.files[0]){
                    formData.append('avatar', vm.$refs.attachment.files[0])
                }

                let uri = { uri: "addUser", data: formData };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch("success", "User record is added successfully");
                    // vm.$router.push("/user/edit/" + response.data.data.user_id);
                    vm.$router.push("/users/");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            updateUser(){
                let vm = this;
                let loader = vm.$loading.show();
                const formData = new FormData()
				formData.append('user_id', vm.user.user_id)
                formData.append('name', vm.user.name)
				formData.append('email', vm.user.email)
				formData.append('mobile_no', vm.user.mobile_no)
                formData.append('role', vm.user.role)
                formData.append('address', vm.user.address)
                if(vm.$refs.attachment.files[0]){
                    formData.append('avatar', vm.$refs.attachment.files[0])
                }

                let uri = { uri: "updateUser", data: formData };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.user = response.data.data;
                    vm.$store.dispatch("success", "User record is updated successfully");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
                        
            getUser(user_id){
                let vm = this;
                let uri = { uri: "getUser", data: { 'user_id': user_id } };
                vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.user = response.data.data                                     
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
                
            discard(){
                let vm = this;
                vm.title = 'Create User',
                vm.user.user_id = '';
                vm.user.name = '';
                vm.user.email = '';
                vm.user.mobile_no = '';
                vm.user.role = '';
                vm.user.address = '';
                vm.user.password = '';
                vm.$router.push("/user/create/");
            },
        }
    }
</script>