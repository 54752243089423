<template>
    <div class="sidebar sidebar-prime">
		<div class="sidebar-header">
			<a href="../index.html" class="sidebar-logo">Rowdy Sheeters</a>
		</div>
		<div id="sidebarMenu" class="sidebar-body">
			<div class="nav-group show">
				<a href="#" class="nav-label">Menu</a>
				<ul class="nav nav-sidebar">
					<li class="nav-item">
						<router-link to="/" class="nav-link">
							<i class="ri-table-line"></i> 
							<span>Dashboard</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/user/create" class="nav-link">
							<i class="ri-user-add-fill"></i> 
							<span>New User</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/users/" class="nav-link">
							<i class="ri-team-fill"></i> 
							<span>Users</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/rowdies/create" class="nav-link">
							<i class="ri-user-add-line"></i> 
							<span>New Rowdy Sheeter</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/rowdies" class="nav-link">
							<i class="ri-team-line"></i> 
							<span>Rowdy Sheeters</span>
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/rowdies/fileupload/" class="nav-link">
							<i class="ri-upload-cloud-2-line"></i> 
							<span>Upload Rowdy Sheeters</span>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
		<div class="sidebar-footer">
			<div class="sidebar-footer-top">
				<div class="sidebar-footer-thumb">
					<img :src="$store.getters.user.avatar" alt="" />
				</div>
				<div class="sidebar-footer-body">
					<h6><a href="../pages/profile.html">{{ $store.getters.user.name }}</a></h6>
					<p>{{ $store.getters.user.role }}</p>
				</div>
				<a id="sidebarFooterMenu" href="#" class="dropdown-link"><i class="ri-arrow-down-s-line"></i></a>
			</div>
			<div class="sidebar-footer-menu">
				<nav class="nav">
					<!-- <a href="#"><i class="ri-edit-2-line"></i> Edit Profile</a> -->
					<router-link to="/profile"><i class="ri-edit-2-line"></i> Edit Profile</router-link>
					<a href="#" @click.prevent="logout"><i class="ri-logout-box-r-line"></i> Log Out</a>
				</nav>
			</div>
		</div>
	</div>
</template>
<script>
export default {
    name: "Menu",
    data() {
        return {
            errors: []
        };
    },
	
    methods: {
		logout() {
			let vm = this;
			vm.$store.dispatch("logout");
			vm.$router.push("/login");
		}
    },
};
</script>